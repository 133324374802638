
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa, FilterFormSAT } from "@/interfaces";
import * as types from "@/store/types";
import { TOKEN } from "@/services/auth/auth-service";
@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        FilterForm: () => import("@/pages/components/forms/FilterFormSAT.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue"),
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Saving extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    filterForm: FilterFormSAT = {
        socio: "",
        company: "",
        client: "",
        installation: "",
        caja: "",
        suiche: false
    };
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    mounted() {
        // eslint-disable-next-line
        this.fetchCajaInit();
        this.fetchFormularyInit();
    }
    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    socios: string[] = [];
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    installations: string[] = [];
    installation = "";
    lockedElem = false;
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 6 || "Maximo 6 dígitos",
        days: value => !!Number(value) || "No es un número"
    };

    nextLock = true;
    steps = 3;
    e1 = 1;

    changeNext() {
        if (this.e1 == 1) {
            if (
                this.socio == "Seleccione un socio" ||
                this.company == "Seleccione una empresa" ||
                this.client == "Seleccione un cliente" ||
                this.installation == "Seleccione una instalación" ||
                this.lugar == ""
            ) {
                this.nextLock = true;
            } else {
                this.nextLock = false;
            }
        } else if (this.e1 == 2) {
            if (this.codigo == "" || this.serie == "" || this.Tcaja == "") {
                this.nextLock = true;
            } else {
                this.nextLock = false;
            }
        }
    }

    changeNext2() {
        if (this.e1 == 1) {
            if (
                this.socio == "Seleccione un socio" ||
                this.company == "Seleccione una empresa" ||
                this.client == "Seleccione un cliente" ||
                this.installation == "Seleccione una instalación" ||
                this.Tcaja == "Seleccione una caja"
            ) {
                this.nextLock = true;
            } else {
                this.nextLock = false;
            }
        } else if (this.e1 == 2) {
            if (
                this.famTip == "" ||
                this.familia == "" ||
                this.eleTip == "" ||
                this.servTip == "" ||
                this.Melees == ""
            ) {
                this.nextLock = true;
            } else {
                this.nextLock = false;
            }
        }
    }

    nextStep() {
        if (this.e1 == 1) {
            if (
                this.socio != "Seleccione un socio" ||
                this.company != "Seleccione una empresa" ||
                this.client != "Seleccione un cliente" ||
                this.installation != "Seleccione una instalación"
            ) {
                if (this.e1 === this.steps) {
                    this.e1 = this.steps;
                } else {
                    this.e1 = this.e1 + 1;
                }
                this.changeNext();
            }
        } else if (this.e1 == 2) {
            if (this.codigo != "" || this.serie != "" || this.Tcaja != "") {
                if (this.e1 === this.steps) {
                    this.e1 = this.steps;
                } else {
                    this.e1 = this.e1 + 1;
                }
                this.nextLock = true;
            }
        }
    }

    lastStep() {
        if (this.e1 == 1) {
            this.e1 = 1;
        } else {
            this.e1 = this.e1 - 1;
        }
        this.nextLock = false;
    }

    cancelStep() {
        this.nextLock = true;
        this.assistant = false;
        this.assistant2 = false;
        this.dialogDelete = false;
        this.filtered = false;
        this.Title = "";
        this.Ncaja = 0;
        this.installation = "";
        this.client = "";
        this.socio = "";
        this.company = "";
        this.Nestanda = 0;
        this.timeZone = "Europe/Madrid";
        this.timeLog = 0;
        this.phone = "";
        this.Falta = "";
        this.Fbaja = "";
        this.serie = "";
        this.codigo = "";
        this.Tcaja = "";
        this.lugar = "";
        this.Tip = "";
    }

    confirmElem() {
        this.e1 = 1;
        this.assistant2 = true;
        this.continueDialog = false;
        if (this.assistant) {
            this.lockedElem = true;
            this.changeNext();
        }
    }

    cache: string[] = [];
    Estandares = [0, 1, 2, 3, 5];
    assistant = false;
    assistant2 = false;
    Ncaja = 0;
    socio = "";
    serie = "";
    codigo = "";
    Tcaja = "";
    lugar = "";
    Nestanda = 0;
    Tip = "";
    timeZones: string[] = [];
    timeZone = "Europe/Madrid";
    timeLog = 0;
    phone = "";
    descripcion = "";
    Falta = "";
    Fbaja = "";
    Title = "";
    filtered = false;
    dialogDelete = false;
    newBoxBool = false;
    continueDialog = false;
    cajaEles: string[] = [];
    cajaEle = "";
    realEles: string[] = [];
    realEle = "";
    eleTips: string[] = [];
    eleTip: string | undefined = "";
    familias: string[] = [];
    familia: string | undefined = "";
    famTips: string[] = [];
    famTip: string | undefined = "";
    servTips: string[] = [];
    servTip: string | undefined = "";
    padreEles: string[] = [];
    padreEle: string | undefined = "0. Raiz";
    nalaTips = { 22: "A", 23: "B", 24: "C", 25: "D", 26: "E" };
    Nalatipo: string[] = [];
    Melees = "";
    Tdirprot = "";
    nelementoE = 0;
    Nprotoco = 0;
    Nfallos = 0;
    Info1 = "";
    Info2 = "";
    Faltasis = "";
    PToler = 25;
    cabecera = [
        {
            text: "Tcodigo",
            align: "start",
            value: "Tcodigo"
        },
        {
            text: "Tserie",
            align: "start",
            value: "Tserie"
        },
        {
            text: "Tcaja",
            align: "start",
            value: "Tcaja"
        },
        {
            text: "Tubi_es",
            align: "start",
            value: "Tubi_es"
        },
        {
            text: "Tlugar",
            align: "start",
            value: "Tlugar"
        },
        {
            text: "Nestanda",
            align: "start",
            value: "Nestanda"
        },
        {
            text: "Tip",
            align: "start",
            value: "Tip"
        },
        {
            text: "Fcajaalt",
            align: "start",
            value: "Fcajaalt"
        },
        {
            text: "Fcajabaj",
            align: "start",
            value: "Fcajabaj"
        },
        {
            text: "Faltasis",
            align: "start",
            value: "Faltasis"
        },
        {
            text: "Fmodific",
            align: "start",
            value: "Fmodific"
        },
        {
            text: "",
            align: "start",
            value: "actions"
        }
    ];
    cajas: any[] = [];

    cabeceraEls = [
        {
            text: "Tubi_es",
            align: "start",
            value: "Tubi_es"
        },
        {
            text: "Tcaja",
            align: "start",
            value: "Tcaja"
        },
        {
            text: "Tlugar",
            align: "start",
            value: "Tlugar"
        },
        {
            text: "Mele_es",
            align: "start",
            value: "Mele_es"
        },
        {
            text: "Tele_es",
            align: "start",
            value: "Tele_es"
        },
        {
            text: "Tip",
            align: "start",
            value: "Tip"
        },
        {
            text: "Faltasis",
            align: "start",
            value: "Faltasis"
        },
        {
            text: "Fmodific",
            align: "start",
            value: "Fmodific"
        }
    ];
    elementos = [];

    cabGrupo = [
        {
            text: "Ngrusat",
            align: "start",
            value: "Ngrusat"
        },
        {
            text: "Tnombre",
            align: "start",
            value: "Tnombre"
        },
        {
            text: "Ttelefon",
            align: "start",
            value: "Ttelefon"
        },
        {
            text: "",
            align: "start",
            value: "actions"
        }
    ];
    grupos: any[] = [];
    cajasInit: any[] = [];
    formulario: any[] = [];
    viewBool = false;

    get Nalatipos() {
        const aux: string[] = [];
        for (const x in this.nalaTips) {
            // console.log(this.nalaTips[x])
            aux.push(this.nalaTips[x].toString());
        }
        return aux;
    }

    async getPadreEle() {
        try {
            const Response = await this.$api.getPadreEle<rdsa>(
                this.installation.split(" - ")[0]
            );
            if (Response.ok) {
                this.padreEles = ["0. Raiz"];
                Response.data.forEach(i => {
                    this.padreEles.push(i["Mele_es"]);
                });
            }
            this.padreEle = "0. Raiz";
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchCajaInit() {
        try {
            const response = await this.$api.getBoxesSATInit<rdsa>();
            if (response.ok) {
                this.cajasInit = this.formatDate(response.data);
                this.cajas = JSON.parse(JSON.stringify(this.cajasInit));
                this.fetchTimezones();
                this.fetchFamTip();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchCajaEle() {
        this.Tcaja = "Seleccione una caja";
        try {
            const response = await this.$api.getBoxesEleSAT<rdsa>(
                this.installation.split(" - ")[0]
            );
            if (response.ok) {
                this.cajaEles = [];
                response.data.forEach(i => {
                    this.cajaEles.push(i["Caja"]);
                });
            }
            if (this.assistant || this.assistant2) {
                this.getRealEle();
                this.getPadreEle();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async getRealEle() {
        try {
            const Response = await this.$api.getRealEle<rdsa>(
                this.installation.split(" - ")[0]
            );
            if (Response.ok) {
                this.realEles = ["0. Raiz"];
                Response.data.forEach(i => {
                    this.realEles.push(i["Tele_es"]);
                });
            }
            this.realEle = "0. Raiz";
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async getGrupCaj() {
        try {
            const Response = await this.$api.getGrupCaj<rdsa>(this.Ncaja);
            if (Response.ok) {
                this.grupos = Response.data;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    grEditIndex = 0;
    editedGroup = {
        Ngrupo: 0,
        Ngrusat: 0,
        Tnombre: "",
        Ttelefon: ""
    };
    dialog = false;
    addGroupBool = false;
    formTitle = "";
    dialogDeleteGro = false;

    editGroup(item) {
        this.formTitle = "Editar grupo";
        this.grEditIndex = this.grupos.indexOf(item);
        this.editedGroup = Object.assign({}, item);
        this.dialog = true;
        this.addGroupBool = false;
    }

    async confirmEditGroup() {
        const JSOON = {
            Ngrupo: this.editedGroup.Ngrupo,
            Ngrusat: this.editedGroup.Ngrusat,
            Tnombre: this.editedGroup.Tnombre,
            Ttelefon: this.editedGroup.Ttelefon,
            Ncaja: this.Ncaja
        };
        try {
            const response = await this.$api.updateGrup<rdsa>(
                // this.client.split(" - ")[0]
                JSOON
            );
            if (response.ok) {
                this.dialog = false;
                this.editedGroup = {
                    Ngrupo: 0,
                    Ngrusat: 0,
                    Tnombre: "",
                    Ttelefon: ""
                };
                this.grEditIndex = 0;
                this.grupos = [];
                this.getGrupCaj();
                this.addGroupBool = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    addGroup() {
        this.formTitle = "Añadir grupo";
        this.grEditIndex = 0;
        this.editedGroup = {
            Ngrupo: 0,
            Ngrusat: 0,
            Tnombre: "",
            Ttelefon: ""
        };
        this.dialog = true;
        this.addGroupBool = true;
    }

    async confirmAddGroup() {
        const JSOON = {
            Tnombre: this.editedGroup.Tnombre,
            Ttelefon: this.editedGroup.Ttelefon,
            Ncaja: this.Ncaja
        };
        try {
            const response = await this.$api.addGrup<rdsa>(
                // this.client.split(" - ")[0]
                JSOON
            );
            if (response.ok) {
                this.dialog = false;
                this.editedGroup = {
                    Ngrupo: 0,
                    Ngrusat: 0,
                    Tnombre: "",
                    Ttelefon: ""
                };
                this.grEditIndex = 0;
                this.grupos = [];
                this.getGrupCaj();
                this.addGroupBool = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    delGroup(item) {
        this.formTitle = "Añadir grupo";
        this.grEditIndex = this.grupos.indexOf(item);
        this.editedGroup = Object.assign({}, item);
        this.dialogDeleteGro = true;
        this.addGroupBool = false;
    }

    async confirmDelGroup() {
        const JSOON = {
            Ngrupo: this.editedGroup.Ngrupo,
            Ncaja: this.Ncaja
        };
        try {
            const response = await this.$api.delGrup<rdsa>(
                // this.client.split(" - ")[0]
                JSOON
            );
            if (response.ok) {
                this.dialog = false;
                this.editedGroup = {
                    Ngrupo: 0,
                    Ngrusat: 0,
                    Tnombre: "",
                    Ttelefon: ""
                };
                this.grEditIndex = 0;
                this.grupos = [];
                this.getGrupCaj();
                this.addGroupBool = false;
                this.dialogDeleteGro = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    cancelGroup() {
        this.dialog = false;
        this.dialogDeleteGro = false;
    }

    async fetchFamTip() {
        try {
            const Response = await this.$api.getFamiTip<rdsa>();
            if (Response.ok) {
                this.eleTips = Response.data["Tip"];
                this.familias = Response.data["Fam"];
                this.famTips = Response.data["Tfam"];
                this.servTips = Response.data["Tserv"];
                this.nalaTips = Response.data["Tala"];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchFormularyInit() {
        try {
            this.nextLock = true;
            const formularyResponse = await this.$api.getFormularyInit<rdsa>();
            if (formularyResponse.ok) {
                this.formulario = formularyResponse.data;
                this.formulario.forEach(i => {
                    this.socios.push(i["socio"]);
                });
                const aux: string[] = [];
                this.socios.forEach(function(item) {
                    if (aux.indexOf(item) < 0) {
                        aux.push(item);
                    }
                });
                this.socios = aux;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    fetchCompany(NOreinicio = false) {
        const filtro = this.formulario.filter(item => item.socio == this.socio);
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["company"]) < 0) {
                aux.push(item["company"]);
            }
        });
        this.companies = aux;
        if (!NOreinicio) {
            this.nextLock = true;
            this.company = "Seleccione una empresa";
            this.client = "Seleccione un cliente";
            this.installation = "Seleccione una instalación";
            if (this.assistant2) {
                this.Tcaja = "Seleccione una caja";
            }
        }
    }

    fetchClient(NOreinicio = false) {
        const filtro = this.formulario.filter(
            item => item.company == this.company
        );
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["client"]) < 0) {
                aux.push(item["client"]);
            }
        });
        this.clients = aux;
        if (!NOreinicio) {
            this.nextLock = true;
            this.client = "Seleccione un cliente";
            this.installation = "Seleccione una instalación";
            if (this.assistant2) {
                this.Tcaja = "Seleccione una caja";
            }
        }
    }

    fetchInstallation(NOreinicio = true) {
        const filtro = this.formulario.filter(
            item => item.client == this.client
        );
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["install"]) < 0) {
                aux.push(item["install"]);
            }
        });

        this.installations = aux;
        if (!NOreinicio) {
            this.nextLock = true;
            this.installation = "Seleccione una instalación";
            if (this.assistant2) {
                this.Tcaja = "Seleccione una caja";
            }
        }
    }

    async fetchTimezones() {
        try {
            const response = await this.$api.getTimeZone<rdsa>();
            if (response.ok) {
                this.timeZones = response.data;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchCaja() {
        const JSOON = {
            socio: this.filterForm.socio,
            company: this.filterForm.company,
            client: this.filterForm.client,
            installation: this.filterForm.installation,
            Tserie: this.filterForm.Tserie,
            Tcaja: this.filterForm.Tcaja,
            Tcodigo: this.filterForm.Tcodigo
        };
        try {
            const response = await this.$api.getBoxesSAT<rdsa>(
                // this.client.split(" - ")[0]
                this.filterForm.suiche,
                JSOON
            );
            if (response.ok) {
                // this.cajas = this.formatDate(response.data);
                this.cajas = this.formatDate(response.data);
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }
    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    }

    //     fetchCaja(){
    //         if(this.filterForm.suiche){}else{}
    //         let a = ["foo","fool","cool","god"];
    // let term = 'oo';
    // let b = a.filter(item => item.toLowerCase().indexOf(term) > -1);
    //     }

    fetchCajaLine(caja) {
        this.viewBool = false;
        this.newBoxBool = false;
        function check(box) {
            return box["install"].split(" - ")[0] == caja.Ninstala;
        }
        const aux = this.formulario.find(check);
        this.Ncaja = caja.Ncaja;
        this.installation = aux["install"];
        this.client = aux["client"];
        this.socio = aux["socio"];
        this.company = aux["company"];
        this.Title = "Modicación de " + caja.Tcaja;
        this.Nestanda = caja.Nestanda;
        this.timeZone = caja.Ttimezon;
        this.timeLog = caja.Ttimelog;
        this.phone = caja.Ttelefon;
        this.Falta = caja.Fcajaalt;
        this.Fbaja = caja.Fcajabaj;
        this.serie = caja.Tserie;
        this.codigo = caja.Tcodigo;
        this.Tcaja = caja.Tcaja;
        this.lugar = caja.Tlugar;
        this.Tip = caja.Tip;
        this.grupos = [];
        this.elementos = [];
        this.fetchCompany(true);
        this.fetchClient(true);
        this.fetchInstallation(true);
    }

    fetchCajaLineView(caja) {
        this.viewBool = true;
        this.newBoxBool = false;
        function check(box) {
            return box["install"].split(" - ")[0] == caja.Ninstala;
        }
        const aux = this.formulario.find(check);
        this.Ncaja = caja.Ncaja;
        this.installation = aux["install"];
        this.client = aux["client"];
        this.socio = aux["socio"];
        this.company = aux["company"];
        this.Title = "Detalles de " + caja.Tcaja;
        this.Nestanda = caja.Nestanda;
        this.timeZone = caja.Ttimezon;
        this.timeLog = caja.Ttimelog;
        this.phone = caja.Ttelefon;
        this.Falta = caja.Fcajaalt;
        this.Fbaja = caja.Fcajabaj;
        this.serie = caja.Tserie;
        this.codigo = caja.Tcodigo;
        this.Tcaja = caja.Tcaja;
        this.lugar = caja.Tlugar;
        this.Tip = caja.Tip;
        this.grupos = [];
        this.elementos = [];
        this.fetchCompany(true);
        this.fetchClient(true);
        this.fetchInstallation(true);
        this.fetchElemento();
        this.getGrupCaj();
    }

    async fetchElemento() {
        const JSOON = {
            socio: this.filterForm.socio,
            company: this.filterForm.company,
            client: this.filterForm.client,
            installation: this.filterForm.installation,
            Ncaja: this.Ncaja,
            Tserie: this.filterForm.Tserie,
            Tcaja: this.filterForm.Tcaja,
            Tcodigo: this.filterForm.Tcodigo
        };
        try {
            const Response = await this.$api.getElementsSAT<rdsa>(
                // this.client.split(" - ")[0]
                this.filterForm.suiche,
                JSOON
            );
            if (Response.ok) {
                // this.cajas = this.formatDate(Response.data);
                this.elementos = this.formatDateEls(Response.data);
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    formatDateEls(data) {
        for (const pl of data) {
            pl.Faltasis = new Date(pl.Faltasis).toISOString().split("T")[0];
            pl.Fmodific = new Date(pl.Fmodific).toISOString().split("T")[0];
        }
        return data;
    }

    formatDate(data) {
        for (const pl of data) {
            pl.Faltasis = new Date(pl.Faltasis).toISOString().split("T")[0];
            pl.Fcajaalt = new Date(pl.Fcajaalt).toISOString().split("T")[0];
            pl.Fcajabaj = new Date(pl.Fcajabaj).toISOString().split("T")[0];
            pl.Fmodific = new Date(pl.Fmodific).toISOString().split("T")[0];
        }
        return data;
    }

    goTo() {
        window.location.replace("/#/v2/form");
    }

    enDelDial() {
        this.dialogDelete = true;
    }

    closeDelete() {
        this.dialogDelete = false;
        this.continueDialog = false;
        this.assistant = false;
        // this.$nextTick(() => {
        //     this.editedItem = Object.assign({}, this.defaultItem);
        //     this.editedIndex = -1;
        // });
    }

    async deleteBox() {
        /*Cuando se borra un proyecto, tambien eliminarlo de la lista de proyectos*/
        try {
            const deleteResponse = await this.$api.deleteBoxSAT<rdsa>(
                this.Ncaja
            );
            if (deleteResponse.ok) {
                this.dialogDelete = false;
                this.Title = "";
                this.showError(
                    "La caja " + this.Tcaja + " se ha eliminado correctamente"
                );
                this.cajas = [];
                if (this.filtered) {
                    this.fetchCaja();
                } else {
                    this.fetchCajaInit();
                }
                this.Ncaja = 0;
                this.installation = "";
                this.client = "";
                this.socio = "";
                this.company = "";
                this.Nestanda = 0;
                this.timeZone = "Europe/Madrid";
                this.timeLog = 0;
                this.phone = "";
                this.Falta = "";
                this.Fbaja = "";
                this.serie = "";
                this.codigo = "";
                this.Tcaja = "";
                this.lugar = "";
                this.Tip = "";
                this.viewBool = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async editBox() {
        const JSOON = {
            Ncaja: this.Ncaja,
            Tcodigo: this.codigo,
            Tserie: this.serie,
            Tcaja: this.Tcaja,
            Nsocio: this.socio,
            Nempresa: this.company,
            Ncliente: this.client,
            Ninstala: this.installation,
            Tlugar: this.lugar,
            Nestanda: this.Nestanda,
            Tip: this.Tip,
            Ttimezon: this.timeZone,
            Ttimelog: this.timeLog,
            Ttelefon: this.phone,
            Fcajaalt: this.Falta,
            Fcajabaj: this.Fbaja
        };
        try {
            const deleteResponse = await this.$api.modifyBoxSAT<rdsa>(JSOON);
            const a = true;
            if (deleteResponse.ok) {
                this.dialogDelete = false;
                this.Title = "";
                this.showError(
                    "La caja " + this.Tcaja + " se ha modificado correctamente"
                );
                this.cajas = [];
                if (this.filtered) {
                    this.fetchCaja();
                } else {
                    this.fetchCajaInit();
                }
                this.Ncaja = 0;
                this.installation = "";
                this.client = "";
                this.socio = "";
                this.company = "";
                this.Nestanda = 0;
                this.timeZone = "Europe/Madrid";
                this.timeLog = 0;
                this.phone = "";
                this.Falta = "";
                this.Fbaja = "";
                this.serie = "";
                this.codigo = "";
                this.Tcaja = "";
                this.lugar = "";
                this.Tip = "";
                this.viewBool = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async addBox() {
        const JSOON = {
            Ncaja: this.Ncaja,
            Tcodigo: this.codigo,
            Tserie: this.serie,
            Tcaja: this.Tcaja,
            Nsocio: this.socio,
            Nempresa: this.company,
            Ncliente: this.client,
            Ninstala: this.installation,
            Tlugar: this.lugar,
            Nestanda: this.Nestanda,
            Tip: this.Tip,
            Ttimezon: this.timeZone,
            Ttimelog: this.timeLog,
            Ttelefon: this.phone,
            Fcajaalt: this.Falta,
            Fcajabaj: this.Fbaja
        };
        try {
            const addResponse = await this.$api.addBoxSAT<rdsa>(JSOON);
            const a = true;
            if (addResponse.ok) {
                this.dialogDelete = false;
                this.Title = "";
                this.showError(
                    "La caja " + this.Tcaja + " se ha creado correctamente"
                );
                this.cajas = [];
                if (this.filtered) {
                    this.fetchCaja();
                } else {
                    this.fetchCajaInit();
                }
                this.Nestanda = 0;
                this.timeZone = "";
                this.timeLog = 0;
                this.phone = "";
                this.Falta = "";
                this.Fbaja = "";
                this.serie = "";
                this.codigo = "";
                if (this.assistant) {
                    this.Ncaja = parseInt(addResponse.data.toString());
                    this.continueDialog = true;
                    // this.showError(
                    //     "La caja " + this.Ncaja + " se ha creado correctamente"
                    // );
                } else {
                    this.e1 = 1;
                    this.lugar = "";
                    this.nextLock = true;
                    this.Ncaja = 0;
                    this.installation = "";
                    this.client = "";
                    this.socio = "";
                    this.company = "";
                    this.Tip = "";
                    this.Tcaja = "";
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async addElemento() {
        const JSOON = {
            Nsocio: this.socio,
            Nempresa: this.company,
            Ncliente: this.client,
            Ninstala: this.installation,
            Nelement: this.realEle,
            Nfamilia: this.familia,
            nelemento: this.nelementoE,
            Nfamtipo: this.famTip,
            Tdirprot: this.Tdirprot,
            Ncaja: this.Tcaja,
            Nserviciotipo: this.servTip,
            Npadre:
                typeof this.padreEle == "undefined"
                    ? ""
                    : this.padreEle.length == 0
                    ? ""
                    : this.padreEle.split(". ")[1],
            Melees: this.Melees,
            Tip: this.Tip,
            Ntipo: this.eleTip,
            Nprotoco: this.Nprotoco,
            Nfallos: this.Nfallos,
            Tinfo1: this.Info1,
            Tinfo2: this.Info2,
            Faltasis: this.Faltasis,
            PToler: this.PToler,
            Nalatipo: this.Nalatipo
        };
        try {
            const addResponse = await this.$api.addElementSAT<rdsa>(JSOON);
            const a = true;
            if (addResponse.ok) {
                this.dialogDelete = false;
                this.Title = "";
                this.showError(
                    "El elemento " + this.Melees + " se ha creado correctamente"
                );
                this.cajas = [];
                if (this.filtered) {
                    this.fetchCaja();
                } else {
                    this.fetchCajaInit();
                }
                this.Nestanda = 0;
                this.timeZone = "";
                this.timeLog = 0;
                this.phone = "";
                this.Falta = "";
                this.Fbaja = "";
                this.serie = "";
                this.codigo = "";
                this.e1 = 1;
                this.lugar = "";
                this.nextLock = true;
                this.Ncaja = 0;
                this.installation = "";
                this.client = "";
                this.socio = "";
                this.company = "";
                this.Tip = "";
                this.Tcaja = "";
                this.assistant = false;
                this.assistant2 = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            //logica de los periodType
            this.Title = "";
            this.grupos = [];
            this.elementos = [];
            this.viewBool = false;
            this.filtered = true;
            this.fetchCaja();
        } else {
            this.$router.push("/signin");
        }
    }

    onFilterReset() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.nextLock = true;
            this.assistant = false;
            this.assistant2 = false;
            this.dialogDelete = false;
            this.filtered = false;
            this.cajas = [];
            this.Title = "";
            this.Ncaja = 0;
            this.installation = "";
            this.client = "";
            this.socio = "";
            this.company = "";
            this.Nestanda = 0;
            this.timeZone = "Europe/Madrid";
            this.timeLog = 0;
            this.phone = "";
            this.Falta = "";
            this.Fbaja = "";
            this.serie = "";
            this.codigo = "";
            this.Tcaja = "";
            this.lugar = "";
            this.Tip = "";
            this.grupos = [];
            this.elementos = [];
            this.viewBool = false;
            this.fetchCajaInit();
        } else {
            this.$router.push("/signin");
        }
    }

    newBox() {
        this.dialogDelete = false;
        this.newBoxBool = true;
        this.Title = "Nueva caja";
        this.Ncaja = 0;
        this.socio = "Seleccione un socio";
        this.company = "Seleccione una empresa";
        this.client = "Seleccione un cliente";
        this.installation = "Seleccione una instalación";
        this.Nestanda = 0;
        this.timeZone = "Europe/Madrid";
        this.timeLog = 0;
        this.phone = "";
        this.Falta = "";
        this.Fbaja = "";
        this.serie = "";
        this.codigo = "";
        this.Tcaja = "";
        this.lugar = "";
        this.Tip = "";
        this.grupos = [];
        this.elementos = [];
        this.viewBool = false;
    }

    setAssistent() {
        this.lockedElem = false;
        this.socio = "Seleccione un socio";
        this.company = "Seleccione una empresa";
        this.client = "Seleccione un cliente";
        this.installation = "Seleccione una instalación";
        this.Tcaja = "";
        this.assistant = true;
        this.assistant2 = false;
        this.newBoxBool = false;
        this.Title = "";
        this.dialogDelete = false;
        this.e1 = 1;
        this.Ncaja = 0;
        this.Nestanda = 0;
        this.timeZone = "Europe/Madrid";
        this.timeLog = 0;
        this.phone = "";
        this.Falta = "";
        this.Fbaja = "";
        this.serie = "";
        this.codigo = "";
        this.Tcaja = "";
        this.lugar = "";
        this.Tip = "";
        this.grupos = [];
        this.elementos = [];
        this.changeNext();
        this.viewBool = false;
    }

    setAssistent2() {
        if (!this.assistant) {
            this.socio = "Seleccione un socio";
            this.company = "Seleccione una empresa";
            this.client = "Seleccione un cliente";
            this.installation = "Seleccione una instalación";
            this.Tcaja = "Seleccione una caja";
            this.Title = "";
            this.Ncaja = 0;
            this.Nestanda = 0;
            this.timeZone = "Europe/Madrid";
            this.timeLog = 0;
            this.phone = "";
            this.Falta = "";
            this.Fbaja = "";
            this.serie = "";
            this.codigo = "";
            this.lugar = "";
            this.Tip = "";
        }
        this.e1 = 1;
        this.assistant = false;
        this.assistant2 = true;
        this.newBoxBool = false;
        this.Title = "";
        this.dialogDelete = false;
        this.newBoxBool = false;
        this.grupos = [];
        this.elementos = [];
        this.viewBool = false;
    }
}
